import React, { useEffect } from "react"
import styled from "styled-components"
import CourseSettings from "../../course-settings"

import BannerImage from "../../banner.svg"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"

const BannerWrapper = styled.header`
  height: 30rem;
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  background-color: #400000;
  /* background-image: url(${BannerImage}); */
  text-align: center;
  position: relative;
  overflow: hidden; /* Ensure particles stay within the banner */
  h1 {
  }
  h2 {
  }
`

const Heading = styled.div`
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, Noto Sans, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  font-size: 2rem;
  color: #c0392b;
  background: white;
  padding: 0.5rem;
  margin: 1rem;
  text-align: center;
  z-index: 1; /* Ensure heading stays on top of particles */
  @media only screen and (min-width: 720px) {
    font-size: 3rem;
  }
`

const SubHeading = styled.div`
    font-family: 'Roboto Slab', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, 'Helvetica Neue', Arial, Noto Sans, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-weight: 500;
    color: #c0392b;
    background: white;
    padding .5rem;
    font-size: 1.4rem;
    margin: 0 1rem;
    z-index: 1; /* Ensure subheading stays on top of particles */
`

const ParticlesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0; /* Particles stay behind the content */
`

const Banner = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      // Create script element
      const script = document.createElement("script")
      script.src =
        "https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js"
      script.async = true

      // Define onload behavior
      script.onload = () => {
        if (typeof window.particlesJS !== "undefined") {
          window.particlesJS(
            "particles-js",

            {
              particles: {
                number: {
                  value: 80,
                  density: {
                    enable: true,
                    value_area: 800,
                  },
                },
                color: {
                  value: "#ffffff",
                },
                shape: {
                  type: "circle",
                  stroke: {
                    width: 0,
                    color: "#000000",
                  },
                  polygon: {
                    nb_sides: 5,
                  },
                  image: {
                    src: "img/github.svg",
                    width: 100,
                    height: 100,
                  },
                },
                opacity: {
                  value: 0.5,
                  random: false,
                  anim: {
                    enable: false,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false,
                  },
                },
                size: {
                  value: 3,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 40,
                    size_min: 0.1,
                    sync: false,
                  },
                },
                line_linked: {
                  enable: true,
                  distance: 150,
                  color: "#ffffff",
                  opacity: 0.5,
                  width: 1,
                },
                move: {
                  enable: true,
                  speed: 0.5,
                  direction: "none",
                  random: false,
                  straight: false,
                  out_mode: "out",
                  bounce: false,
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                  },
                },
              },
              interactivity: {
                detect_on: "canvas",
                events: {
                  onhover: {
                    enable: false,
                    mode: "repulse",
                  },
                  onclick: {
                    enable: true,
                    mode: "push",
                  },
                  resize: true,
                },
                modes: {
                  grab: {
                    distance: 400,
                    line_linked: {
                      opacity: 1,
                    },
                  },
                  bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 8,
                    speed: 3,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                  push: {
                    particles_nb: 4,
                  },
                  remove: {
                    particles_nb: 2,
                  },
                },
              },
              retina_detect: true,
            },
          )
        } else {
          console.error("particlesJS is not available.")
        }
      }

      // Append the script to the body
      document.body.appendChild(script)
    }
  }, [])

  return (
    <BannerWrapper>
      <ParticlesWrapper id="particles-js" />
      <Heading>{CourseSettings.default.name}</Heading>
      <SubHeading>{CourseSettings.default.subtitle}</SubHeading>
    </BannerWrapper>
  )
}

export default withSimpleErrorBoundary(Banner)
